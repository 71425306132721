import React from "react"
import PropTypes from "prop-types"

import { Container, Row, Col } from "@ui/wrapper"
import Heading from "@ui/heading"
import Text from "@ui/text"

import SectionTitle from "@ui/section-title"
import { AboutWrapper, LeftBox, RightBox } from "./about-area.style"
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import "./style.css"
const AboutDesc = ({
  sectionTitleStyle,
  headingStyle,
  textStyle,
  descStyle,
  btnStyle,
}) => {
  // const AboutDescData = useStaticQuery(graphql`
  //     query AboutPageDescQuery {
  //         aboutUsJson(id: {eq: "about-page-intro"}) {
  //             section_title {
  //                 title
  //                 subtitle
  //             }
  //             heading
  //             text
  //             desc
  //             path
  //         }
  //     }
  // `);
  // const { section_title: { title, subtitle }, heading, text, desc, path } = AboutDescData.aboutUsJson;
  const intl = useIntl()
  return (
    <AboutWrapper className="about-us-area">
      <Container>
        <Row>
          <Col lg={7} md={9} ml="auto" mr="auto">
            <SectionTitle
              {...sectionTitleStyle}
              title={intl.formatMessage({ id: "about_us" })}
              
            />
          </Col>
        </Row>
        <Row>
          <Col lg={{ span: 6 }}>

          <RightBox style={{backgroundColor:"#eee",padding: "28px",height: "470px"}}>
              <Text {...descStyle}>
                <p className="about-us-comapeny-desc">
                  {" "}
              <p> <FormattedMessage id="about_desc" /></p>  
              <p> <FormattedMessage id="about_desc1" /></p>
              <p> <FormattedMessage id="about_desc2" /></p>
              <p>  <FormattedMessage id="about_desc4" /></p>
                </p>
              </Text>
            </RightBox>
            
          </Col>
          <Col lg={{ span: 6 }}>
          <LeftBox style={{backgroundColor:"rgb(226 227 232)",  padding: "28px",height: "470px"}}>
            <h3 className="about-us-left-box-title"><FormattedMessage id="construct_vision_message" /></h3>
            <Text {...descStyle}>
                <p className="about-us-comapeny-desc">
                  {" "}
              <p> <FormattedMessage id="construct_vision_message_desc" /></p>  
              
                </p>
              </Text>
            </LeftBox>
          </Col>
        </Row>
      </Container>
    </AboutWrapper>
  )
}

AboutDesc.propTypes = {
  sectionStyle: PropTypes.object,
  sectionTitleStyle: PropTypes.object,
  headingStyle: PropTypes.object,
  textStyle: PropTypes.object,
  descStyle: PropTypes.object,
}

AboutDesc.defaultProps = {
  sectionTitleStyle: {
    mb: "70px",
    responsive: {
      small: {
        mb: "30px",
      },
    },
  },
  headingStyle: {
    as: "h3",
    layout: "highlight",
    maxwidth: "330px",
    mb: "24px",
    responsive: {
      medium: {
        maxwidth: "100%",
      },
    },
  },
  textStyle: {
    as: "h4",
    fontSize: "20px",
  },
  descStyle: {
    fontSize: "18px",
    lineHeight: 1.67,
    mb: "23px",
  },
  btnStyle: {
    varient: "texted",
    iconname: "far fa-long-arrow-right",
    hover: "false",
  },
}

export default AboutDesc
