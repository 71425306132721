import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Heading from "@ui/heading";

import { Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import FeatureBox from "@components/box-image/layout-one";
import { SectionWrap } from './features-area.style'
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import service1 from "../../../assets/images/icons/hotel.png";
import service2 from "../../../assets/images/icons/website.png";
import service3 from "../../../assets/images/icons/verified.png";
import service4 from "../../../assets/images/icons/design.png";



import "./style.css"
const FeaturesArea = (props) => {
	const featuredDataQuery = useStaticQuery(graphql`
        query infotechnoFeatureQueryData {
            indexInfotechnoJson(id: {eq: "infotechno-featured-content"}) {
                title
                subtitle
            }
            allItServicesJson(sort: {order: DESC, fields: id}, filter: {is_featured: {eq: true}}, limit: 3) {
                edges {
                  node {
                    fields{
                        slug
                    }
                    id
                    title
                    excerpt
                    icon {
                      img{
                        childImageSharp{
							gatsbyImageData(
								layout: FIXED
								width: 100
								height: 108
								placeholder: TRACED_SVG
								formats: WEBP
								quality: 100
							)
                        }
                      }
                    }
                  }
                }
            }
        }
    `);
	const featureSecData = featuredDataQuery.indexInfotechnoJson;
	const featureData = featuredDataQuery.allItServicesJson.edges;
	const { featureBoxStyles, linkStyle, headingStyle } = props;
	const intl = useIntl()
	return (
		<SectionWrap>
			<div className="container container-services" >
				<Row>
					<Col lg={12}>
						<SectionTitle
							title={intl.formatMessage({ id: "services" })}
						/>
					</Col>
				</Row>
				<Row>
				<Col lg={3} md={6} >
							<FeatureBox
								{...featureBoxStyles}
								title={<FormattedMessage id="engineering_designs" />}
								imageSrc={service1}

								
								path={`/engineering-designs/`}
							/>
						</Col>
						<Col lg={3} md={6} >
							<FeatureBox
								{...featureBoxStyles}
								title={<FormattedMessage id="consulting_services" />}
								imageSrc={service4}
								
								path={`/general-technical-advisory/`}
							/>

						</Col>
						<Col lg={3} md={6} >
							<FeatureBox
								{...featureBoxStyles}
								title={<FormattedMessage id="study_request" />}
								imageSrc={service2}
								
								path={`/study-request/`}
							/>
						</Col>
						<Col lg={3} md={6} >
							<FeatureBox
								{...featureBoxStyles}
								title={<FormattedMessage id="technical_advice_request" />}
								imageSrc={service3}
								
								path={`/consultation-request/`}
							/>
						</Col>
					
				</Row>
				
			</div>
		</SectionWrap>
	)
}

FeaturesArea.propTypes = {
	featureBoxStyles: PropTypes.object,
	headingStyle: PropTypes.object,
	linkStyle: PropTypes.object
}

FeaturesArea.defaultProps = {
	featureBoxStyles: {
		mt: '60px',
		responsive: {
			small: {
				mt: '47px'
			}
		}
	},
	headingStyle: {
		as: 'h3',
		fontSize: '18px',
		fontweight: 500,
		lineHeight: 1.40,
		color: '#333333',
		mt: '60px',
		textalign: 'center',
		responsive: {
			small: {
				mt: '45px'
			}
		}
	},
	linkStyle: {
		layout: 'underline',
		hover: {
			layout: 2
		}
	}
}

export default FeaturesArea;