import * as React from "react"
import Seo from "@components/seo"
import Layout from "@layout"
import Header from "@layout/header/header-one"
import Footer from "@layout/footer/footer-one"
import PageHeader from "@containers/about-us/page-header"
import AboutArea from "@containers/about-us/about-area"
import ContactArea from "@containers/index-infotechno/contact-area"
import FeaturesArea from "@containers/index-infotechno/features-area"


const AboutPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="About Us" />
    <Header />
    <main className="site-wrapper-reveal">
      <PageHeader />
      <AboutArea />
      <FeaturesArea />
      <ContactArea />
    </main>
    <Footer />
  </Layout>
)

export default AboutPage
